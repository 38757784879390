@import './fonts';

body {
  & > * + * {
    margin-top: 16px;
  }
}

// NEW PRIMARY BTN

// button-variant(background, border-color, hover:background-color, hover:border-color, active:background-color, active:border-color)

.btn-primary {
  @include button-variant(
    $im-green,
    $im-green,
    darken($im-green, 10%),
    darken($im-green, 10%),
    lighten($im-green, 10%),
    lighten($im-green, 10%)
  );
  border: 1px solid $im-green;

  //line-height: 100%;
  //padding: 18px 22px 11px;
  text-transform: uppercase;
}

.btn,
.nav-item {
  text-transform: uppercase;
}

.shoppingcart-finisher {
  background-color: rgba($im-sand, 0.8);
}

.sticky-bottom.is-valid.when-valid .btn-primary,
.shoppingcart-finisher .btn-primary {
  transition: all 300ms ease;
  &:hover {
    letter-spacing: 3px;
  }
}

.media-heading {
  font-family: $font-family-sans-serif;
}

body,
body.ticket-listing-page,
body.product-listing-page,
body.product-detail-page,
body.form-page,
.checkout .tt-listing,
.checkout-confirm-address .tt-listing {
  color: $white;
  #root {
    padding-top: 0;
  }
  #main {
    background-color: $body-bg;
  }
  #product-listing {
    min-height: 50vh;
  }
}

.modal-content {
  color: $gray-900;
}

// FIXING BACKGROUND FORM GROUP -> DIFFERENT SHIPPING ADDRESS

.checkout .form-group .bg-light {
  background-color: black !important;
  border: 1px solid white;
}

//FILTER BUTTONS OVER TICKET LISTING

.container.listing-filter-buttons-container {
  // padding-left: 0;
  // padding-right: 0;
}

.listing-filter-buttons-container {
  h5 {
    text-align: center;
    margin-top: 1.5rem;
  }
}
.listing-buttons-container {
  .btn {
    // border-radius: 0;
    font-size: $h5-font-size;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover,
    &:active,
    &:focus {
      background-color: $im-green !important;
      color: $white !important;
    }
  }
}

body.merch-listing-page {
  #main {
    //background-color: $red;
  }
  #product-listing {
    min-height: 50vh;
  }
  .superh1 {
    font-size: 3em;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 1px #fff;
  }
}

body.product-detail-page {
  #main {
    //background-color: $green;
  }
  .container.detail-page {
    //color: $white;
  }
  .carousel-control-prev,
  .carousel-control-next {
    //color: $white;
  }
  .carousel-indicators {
    @include media-breakpoint-down(md) {
      display: none;
    }
    position: relative;
    bottom: 0;
  }
  .product-thumb {
    background-color: transparent !important;
    height: 60px;
    width: 60px;
    margin: 5px;
  }
  header {
    // @include media-breakpoint-down(md) {
    //   margin-top: 80px !important;
    // }
  }
}

.product-content {
  background: transparent;
  a {
    //color: $white;
  }
  a:hover {
    //color: rgba($white, 30%);
  }
}
html {
  font-size: 12pt;
}

#root {
  @include media-breakpoint-up(lg) {
    padding-top: 7rem;
  }
}

#footer {
  background: $black;
  font-size: 1rem;
  min-height: auto;
  color: $white;
  a {
    color: $white;
  }
  .copyright {
    font-size: 0.8rem;
    line-height: 1rem;
  }
  .copyright-logos {
    img {
      max-height: 2rem;
      filter: invert(1);
    }
    margin-top: 25px;
  }
}

.headerimage {
  background: $body-bg url('./assets/images/header.png') no-repeat center border-box;
  background-size: cover;
  height: 50vh;
  width: 100vw;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  @include media-breakpoint-up(md) {
    height: 50vh;
    background: $body-bg url('./assets/images/header.png') no-repeat center border-box;
    background-size: cover;
  }
  @include media-breakpoint-up(lg) {
    height: 75vh;
    background: $body-bg url('./assets/images/header.png') no-repeat center border-box;
    background-size: cover;
    //margin-top: -$calculated-navbar-height;
  }
  .headerimage-content {
    width: 75vw;
    @include media-breakpoint-down(sm) {
      width: 100vw;
      padding: 20px !important;
    }
    h1 {
      color: $white;
    }
  }
  border-bottom: 70px solid transparent;
  padding-bottom: -65px;
  border-image: url('./assets/images/ikkimel_chains_shop.png') 500 round;
}

// .headerimage-tour {
//   background: $body-bg url('./assets/images/maex_head_tour.jpg') no-repeat center top;
//   background-size: cover;
//   height: 35vh;
//   width: 100vw;
//   display: flex;
//   justify-content: flex-end;
//   align-items: flex-end;
//   @include media-breakpoint-up(md) {
//     height: 50vh;
//     background: $body-bg url('./assets/images/maex_head_tour.jpg') no-repeat center center;
//     background-size: cover;
//   }
//   @include media-breakpoint-up(lg) {
//     height: 70vh;
//     background: $body-bg url('./assets/images/maex_head_tour.jpg') no-repeat center center;
//     background-size: cover;
//     //margin-top: -$calculated-navbar-height;
//   }
//   .headerimage-content {
//     width: 75vw;
//     @include media-breakpoint-down(sm) {
//       width: 100vw;
//       padding: 20px !important;
//     }
//     h1 {
//       color: $white;
//     }
//   }
// }

// .masonry .maki-container {
//   align-items: flex-start;
// }

// .maki.card .card-body {
//   padding: $card-spacer-x;

//   .card-title {
//     @extend .text-truncate;
//     .title {
//       @extend .text-truncate;
//       font-weight: 900;
//     }
//   }
// }

h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5 {
  font-weight: 700 !important;
  text-transform: uppercase;
}

h1.superhead {
  @include font-size(10rem);
  //text-shadow: 1px 1px 1px black;
  color: $im-green !important;
}

.nav-item {
  @include media-breakpoint-up(md) {
    font-size: 1rem;
  }
}

.nav-link {
  letter-spacing: 1px;
  font-weight: 500;
  transition: all 300ms ease;
  &:hover {
    letter-spacing: 3px;
  }
}

#main-menu {
  background: transparent;

  .nav-link {
    background-color: $black; //$light-milk;
    color: $white !important;
    margin-bottom: 0.4rem;
    font-weight: 500;
    text-transform: uppercase;
  }
}

.secondary-menu .menu {
  display: none;
  @include media-breakpoint-up(lg) {
    display: flex;
  }
}

#header > .navbar {
  height: $calculated-navbar-height-sm;
  box-shadow: none;
  background: transparent !important;
  @include media-breakpoint-up(md) {
    height: $calculated-navbar-height * 1.5;
    font-size: 22px;
    padding-left: 2vw;
    padding-right: 2vw;
  }

  .nav-link {
    color: $white;
  }

  .navbar-brand {
    padding: 0 15px;
    @include media-breakpoint-down(sm) {
      font-size: 1.1rem;
    }
    img {
      height: 2rem;
      width: auto;
      max-height: 2rem;
    }
    @include media-breakpoint-up(md) {
      img {
        height: 4rem;
        width: auto;
        max-height: 4rem;
      }
    }
  }
}

.offcanvas-collapse {
  background-color: $white;
  top: $calculated-navbar-height + 1rem;
  @include media-breakpoint-down(sm) {
    top: $calculated-navbar-height-sm + 1rem;
  }
}

video {
  background: $dark;
}

#cart-menu,
#main-menu {
  font-size: 16px;
  h5 {
    color: $gray-900;
  }
}
#main-menu {
  bottom: auto;
  overflow: visible;
}

.logo-container {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: $black;
  pointer-events: none;
}

// .logo-name {
//   // font-size: 18px;
//   @include media-breakpoint-up(md) {
//     font-size: 40px;
//   }
//   font-weight: 900;
//   font-style: normal;
// }
// .logo-branch {
//   @extend .d-none;
//   @extend .d-md-inline;
//   font-size: 30px;
//   font-weight: 500;
//   font-style: normal;
//   color: rgba(0, 0, 0, 0.5);
//   position: relative;
//   padding-left: 30px;

//   &::before {
//     position: absolute;
//     content: '';
//     left: 15px;
//     top: 50%;
//     transform: translateY(-50%);
//     width: 3px;
//     height: 35px;
//     background-color: currentColor;
//   }
// }

.product-listing {
  background: $info;
}

// MAKIS IN DA HOOD

.maki.card {
  overflow: hidden;
  background-color: transparent;

  .card-title {
    display: block;
    text-align: left;
    padding-bottom: 0rem;

    .title {
      font-weight: 700 !important;
      font-family: $headings-font-family;
      font-size: $h4-font-size;
      text-transform: uppercase;
      //padding-bottom: 0.8rem;
      //font-size: 13px;
      display: block;
      width: 100%;
    }

    .sub-title {
      font-size: $font-size-sm;
      display: block;
      width: 100%;
      color: $white;
    }

    .price {
      font-weight: bold;
      display: inline-block;
    }

    .former-price {
      text-decoration: line-through;
      display: inline-block;
      //font-size: 13px;
      //line-height: 1.6rem;
      color: $secondary !important;
    }
  }

  .maki-img-container {
    overflow: hidden;
    background-color: #fff;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .card-header {
    color: $white;
  }
}

.product-detail-title {
  font-style: normal;
  font-size: 3rem;
  @include media-breakpoint-up(md) {
    //font-size: 55px;
  }
  position: relative;
  margin-top: 0;
  margin-bottom: map-get($spacers, 4);

  small  {
    font-family: $font-family-sans-serif;
    font-size: 1.5rem;
    margin-top: 0.5rem;
    text-transform: none;
  }

  // &::before {
  //   content: '';
  //   display: block;
  //   position: absolute;
  //   left: 50%;
  //   bottom: map-get($spacers, 2) * -1;
  //   width: 85px;
  //   height: 3px;
  //   background-color: $black;
  //   transform: translateX(-50%);

  //   @include media-breakpoint-up(md) {
  //     left: 0;
  //     width: 200px;
  //     transform: translateX(0);
  //   }
  // }
}

// MAKI 2020 OVERWRITES

.maki-2020 {
  &,
  &:link,
  &:hover,
  &:focus,
  &:active {
    color: $card-color;
    text-decoration: none;
  }
  flex: 1;
  box-shadow: none;
  overflow: hidden;
  transition: box-shadow 0.2s ease;
  border: 0;

  .maki-img,
  .maki-img-hover {
    transition: opacity 0.5s;
  }

  .maki-img-hover {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: none;
  }

  &:hover {
    // .maki-img {
    //   opacity: 0.75;
    // }
    .maki-img-hover {
      opacity: 1;
    }
  }

  .card-body {
    background: transparent;
    backdrop-filter: none;

    .card-title {
      color: $white;
    }

    text-align: center;
    position: relative;
    box-shadow: none;
    transition: box-shadow 0.2s ease;

    .overlay {
      position: absolute;
      top: auto;
      bottom: 92%;
      left: -10%;
      right: -10%;
      display: flex;
      justify-content: center;
      padding: $badge-padding-y $badge-padding-x;
      transform: rotate(-2deg);
      background: fade-out($yellow, 0.9);
      color: $white;
      opacity: 1;
      transition: all 0.2s;
      // @extend .small;
    }

    .sub-title {
      display: none;
    }

    small  {
      font-family: $font-family-sans-serif;
      text-transform: none;
    }
  }

  .card-img-overlay {
    top: auto;
    padding: 0;
    // max-height: 100%;
    // overflow-y: auto;
  }

  // .active-menu {
  // }

  &:hover,
  &:focus,
  &:active {
    box-shadow: $box-shadow-lg;
    .overlay {
      //opacity: 0.7;
    }
    .card-body {
      box-shadow: $box-shadow-inverse-xl;
      background: fade-out($pink, 0.15);
      .card-title {
        color: $white;
      }
    }
  }
}

// safari fix from-to dates on nigiri
@include media-breakpoint-up(lg) {
  .nigiri-date {
    text-align: center;
    flex-direction: column;
  }

  .nigiri-date * + * {
    margin-top: -1rem;
  }
}

// GIN + GLAS

#article-42476 {
  .maki-2020 .card-body .card-title {
    color: $white !important;
  }
}

#article-42477 {
  .maki-2020 .card-body .card-title {
    color: $white !important;
  }
}

// TAGS

// 3491 = "sale" tag
.maki-2020.tag-id-5648 {
  &::after {
    content: 'SALE';
    background-color: $red;
    color: $white;
    padding: 0.25rem 0.75rem;
    font-weight: bold;
    display: inline-block;
    position: absolute;
    z-index: 1;
    top: 15px;
    right: -2px;
    transform: translate(-50%, -50%);
    transform: rotate(-4deg);
    // width: 80px;
    // height: 80px;
    //background: $af-yellow url('./assets/images/po.svg') no-repeat center center;
    //background-size: contain;
  }
  &:hover {
    &::after {
      opacity: 0.8;
    }
  }
}

// 3491 = "sale" tag
.maki-2020.tag-id-5705 {
  &::before {
    //@extend .rounded-circle;
    content: 'EXKLUSIV';
    background-color: $yellow;
    color: $white;
    padding: 0.25rem 0.75rem;
    font-weight: bold;
    display: inline-block;
    position: absolute;
    z-index: 1;
    top: 15px;
    right: -2px;
    transform: translate(-50%, -50%);
    transform: rotate(-4deg);
    // width: 80px;
    // height: 80px;
    //background: $af-yellow url('./assets/images/po.svg') no-repeat center center;
    //background-size: contain;
  }
  &:hover {
    &::after {
      opacity: 0.8;
    }
  }
}

.navbar .hamburger-inner,
.navbar .hamburger-inner:after,
.navbar .hamburger-inner:before {
  width: 30px;
  height: 2px;
}

.navbar .hamburger.is-active .hamburger-inner,
.navbar .hamburger.is-active .hamburger-inner:after,
.navbar .hamburger.is-active .hamburger-inner:before {
  background-color: $black;
}

.expiration {
  display: none;
}

.nigiri.card {
  background-color: transparent;
  border: 0;
  // margin-bottom: 0 !important;
  background-color: $white;

  //border-bottom: 1px solid rgba($white, 0.25);
  .nigiri-content {
    padding: 0rem;
    color: $black;
    @include media-breakpoint-up(md) {
      align-items: center;
      grid-template-columns: 15rem 2fr auto;
    }
    @include media-breakpoint-down(md) {
      display: table;
    }
  }
  .nigiri-date {
    font-size: $h3-font-size;
    padding: 1.25rem;
    color: $black;
    align-items: center !important;

    // background-image: linear-gradient(to top, black 33%, rgba(255, 255, 255, 0) 0%);
    // background-position: right;
    // background-size: 3px 10px;
    // background-repeat: repeat-y;
    @include media-breakpoint-down(md) {
      background-image: none;
      padding-bottom: 0;
    }
  }

  .nigiri-body {
    font-size: $h2-font-size;
    padding: 1.25rem;
    border-left: 0;
    //text-transform: uppercase;
    font-weight: bold;
    padding-left: 3rem;
    @include media-breakpoint-down(md) {
      padding: 0;
      padding-bottom: 1.25rem;
      margin-bottom: 0;
      line-height: 2rem;
    }
  }
  .badge {
    z-index: 2;
    font-size: 0.8em;
  }
  .title {
    font-size: $font-size-base * 1.2;
    margin-bottom: 0;
    color: $black;
    font-family: $font-family-sans-serif !important;
  }
  .subtitle {
    font-size: $font-size-base;
    margin-bottom: 0;
    color: $gray-500;
    font-weight: normal;
    @include media-breakpoint-down(md) {
      line-height: 1rem;
      //margin-bottom: 0.5rem;
    }
  }
  .location {
    font-weight: 700 !important;
    font-size: $h3-font-size !important;
    text-transform: uppercase;
    color: $black;
    @include media-breakpoint-down(md) {
      //margin-bottom: 1rem;
      padding: 0 1.25rem;
    }
  }
  .nigiri-cta {
    padding: 1.25rem;
    // @include media-breakpoint-down(md) {
    //   background-image: linear-gradient(to right, black 33%, rgba(255, 255, 255, 0) 0%);
    //   background-position: top;
    //   background-size: 10px 3px;
    //   background-repeat: repeat-x;
    // }
    .btn.btn-primary {
    }
  }
  &:hover {
    background-color: lighten($primary, 20%);
    .nigiri-date,
    .location {
      color: $white;
    }
  }
}

// TICKET DETAIL PAGE

.ticket-detail-page {
  .blurry-background-image {
    //height: 50vh;
    background-color: $gray-800;
  }

  .blurry-background-image:before {
    filter: blur(1.5rem);
    opacity: 0.8;
    background-position: center center;
  }

  // .blurry-background-image:before {
  //   background-image: none;
  // }
  .carousel-inner {
    //display: none !important;
  }

  .article-title {
    padding-left: 0;
    padding-right: 0;
    background: transparent !important;
    border: 0;
    border-bottom: 0.1rem solid rgba($black, 0.08);

    .super-title {
      font-size: 0.7rem;
      opacity: 1;
    }
  }

  .product-info-bar {
    background-color: transparent;
    font-family: $headings-font-family;
    text-transform: uppercase;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 0.1rem solid rgba($black, 0.08);
    box-shadow: none;
    i.far {
      color: $secondary !important;
    }
    .navbar-text {
      text-align: left;
      margin-left: 0;
      display: block;
    }
  }
  .variant-listing {
    .visible,
    .invisible {
      display: none !important;
    }
  }
}
